import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
} from '@chakra-ui/react';
import { formatOnboardingSurveyData } from './utils';
import {
  fetchBreweryByUserUid,
  updateBreweryOnboardingSurvey,
} from '../../../../api';
import { auth } from '../../../../firebase';

export default function ClubDetails(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMugClub, setHasMugClub] = useState<string>();
  const [numberOfMembers, setNumberOfMembers] = useState<string>();
  const [hasRewardsProgram, setHasRewardsProgram] = useState<string>();
  const [clubChallenges, setClubChallenges] = useState<(string | number)[]>([]);
  const navigate = useNavigate();

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const { currentUser } = auth;

      if (!currentUser) {
        return;
      }

      const brewery = await fetchBreweryByUserUid(currentUser.uid);

      if (!brewery) {
        return;
      }

      const onboardingSurveyData = formatOnboardingSurveyData({
        hasMugClub,
        numberOfMembers,
        hasRewardsProgram,
        clubChallenges,
      });

      await updateBreweryOnboardingSurvey(brewery.id, onboardingSurveyData);
    } catch (error) {
    } finally {
      navigate('/auth/clubs');
    }
  }

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <FormControl mb={4}>
        <FormLabel>Do you already have a mug club?</FormLabel>
        <RadioGroup
          onChange={(value) => setHasMugClub(value)}
          display="flex"
          flexDir="column"
          gap={2}
        >
          <Radio value="true">Yes</Radio>
          <Radio value="false">No</Radio>
        </RadioGroup>
      </FormControl>

      <FormControl mb={4}>
        <FormLabel>How many members are in your club?</FormLabel>
        <Input
          placeholder="Number of mug club members"
          onChange={({ target }) => setNumberOfMembers(target.value)}
        />
      </FormControl>

      <FormControl mb={4}>
        <FormLabel>
          Do you have a rewards program as part of your mug club?
        </FormLabel>
        <RadioGroup
          onChange={(value) => setHasRewardsProgram(value)}
          display="flex"
          flexDir="column"
          gap={2}
        >
          <Radio value="true">Yes</Radio>
          <Radio value="false">No</Radio>
        </RadioGroup>
      </FormControl>

      <FormControl mb={6}>
        <FormLabel>What are some challenges with running your club?</FormLabel>
        <CheckboxGroup onChange={(value) => setClubChallenges(value)}>
          <Checkbox value="membershipManagement">
            Membership management (who is active vs. inactive)
          </Checkbox>
          <Checkbox value="newMemberSignUp">
            Signing new members up to the club
          </Checkbox>
          <Checkbox value="membershipRenewals">
            Processing membership renewals
          </Checkbox>
          <Checkbox value="membershipPayments">
            Collecting membership payments
          </Checkbox>
          <Checkbox value="trackingMemberVisits">
            Tracking member visits
          </Checkbox>
          <Checkbox value="trackingMemberRewards">
            Tracking member rewards
          </Checkbox>
          <Checkbox value="drivingMemberVisits">
            Bringing members into the taproom more regularly
          </Checkbox>
        </CheckboxGroup>
      </FormControl>

      <Button w="100%" type="submit" isLoading={isLoading}>
        Submit
      </Button>
    </Box>
  );
}
